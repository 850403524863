.alluser-container {
  background: whitesmoke;
  min-height: 100vh;
  position: relative;
}

.kpiInterest{
  display: flex;
    justify-content: start;
    gap: 20%;
    margin-left: 10%;
}

/* loader */
.loader-gif {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;

  z-index: 100;
}
.loader-gif img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.filter-option {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
  background: whitesmoke;
}

.total-users {
  width: 20%;
  height: 150px;
  background: var(--bgWhite);
  padding-block: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding-inline: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  position: relative;
}
.user-numbers {
  font-size: 22px;
}

.icon-heading {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.icon-heading svg {
  color: #306eff;
  font-size: 1.3rem;
}
.date-selection {
  width: 55%;
  height: 150px;
  background: var(--bgWhite);
  padding-block: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding-inline: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.filter-all-users{
  width: 55%;
  height: 200px;
  background: var(--bgWhite);
  padding-block: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding-inline: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.prem-selection {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.left-filter {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
  width: 45%;
}

.right-filter {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
  width: 45%;
}
.str-date {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.str-date input[type="date"] {
  width: 120px;
  height: 30px;
}
.filter-btn {
  border-radius: 4px;
  border: none;
  width: 100px;
  height: 30px;
  color: white;
  background: #10111f;
  cursor: pointer;
}
.btn-csv {
  width: 80px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.btn-csv svg {
  font-size: 1.3rem;
}
.name-select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right-filter input[type="text"] {
  width: 150px;
  height: 30px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid gray;
}
.btn-filter-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.prem-selection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.prem-option {
  display: flex;
  width: 120px;
  gap: 20px;
}
.check-box-div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.check-box-div input[type="checkbox"] {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.table-container {
  margin-top: 50px;
  border-radius: 5px;
  border: 2.14px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    86deg,
    rgba(255, 255, 255, 0.2) 11.14%,
    rgba(255, 255, 255, 0.04) 113.29%
  );
  box-shadow: 0px 0.8535px 21.33751px 0px rgba(69, 42, 124, 0.1);
  backdrop-filter: blur(24.964454650878906px);
  width: 100%;
  min-height: 500px;
  position: relative;
  padding-bottom: 50px;
  overflow-x: auto;
}
.main-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}
.main-table th {
  background: #10111f;
  text-align: center;
  height: 30px;
  color: white;
}
.sorting-row {
  position: relative;
}
.sorting-option {
  position: absolute;
  background: white;
  color: black;
  right: 0px;
  margin-top: 10px;
  width: 120px;
  height: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 20;
  overflow: hidden;
  transition: 0.3s;
}
.sorting-option li {
  list-style: none;
  padding-block: 8px;
}
.sorting-option li:hover {
  background: lightgray;
}
.main-table tr {
  text-align: center;
  height: 60px;
  transition: 0.3s;
}
.main-table tr:hover {
  background: rgb(196, 195, 195);
  transition: 0.3s;
  cursor: pointer;
}
.main-table th,
td {
  border-bottom: 1px solid #ddd;
}

.user-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: crimson;
  margin-left: 40px;
}
.user-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* big image */
.big-image{
  width: 150px;
  height: 150px;
  margin:10px;
  border:1px solid black;
}
.big-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-know {
  width: 70%;
  height: 30px;
  border-radius: 5px;
  border: none;
  background: var(--btnColor);
  color: white;
  cursor: pointer;
}
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 450;
  padding-inline: 10px;
  padding-block: 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.sorry-nothing {
  font-size: 2.5rem;
}
.reset-btn {
  background: var(--btnColor);
}

/* View More */
.view-more {
  background: whitesmoke;
}
.btn-back {
  position: absolute;
  left: 0px;
  width: 120px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  top: 20px;
  background: var(--blueColor);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.view-user-container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.user-name-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
}
.user-image {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

.user-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.user-details {
  max-width: calc(100% - 200px);
  height: auto;

  padding-block: 20px;
  padding-inline: 20px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.single-user-name-div {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.view-more-detail {
  display: flex;
  gap: 10px;
}

.token-div p {
  width: 80%;
  color: red;
  word-wrap: break-word;
}
.btn-block {
  width: 120px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn-unblock {
  width: 120px;
  background: green;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.location-div {
  width: 23%;
}
.btn-copy {
  width: 120px;
  background: rgb(8, 159, 209);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delete-inte {
  color: red;
  font-size: 1.5rem;
  cursor: pointer;
}

.btn-interest {
  width: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--blueColor);
}

.load-more-div {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
/* .btn-load-more {
  width: 120px;
  margin-top: 20px;
} */
.page-number-div {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.page-btn {
  width: 40px;
  height: 30px;
  border: none;
  border-radius: 5px;
  display: grid;
  place-items: center;
  background: var(--blueColor);
  color: white;
  cursor: pointer;
}
/* add interest */
.interest-add-div {
  width: 100%;

  height: calc(100vh - 80px);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}

/* show interset */
.show-interest-div {
  width: 100% !important;
  height: auto;
  overflow: hidden;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-inline: 20px;
  flex-wrap: wrap;
  min-width: 200px !important;
  overflow-x: auto !important;
  
}

.modal-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

/* view more subscription */

.user-subcription-details {
  background: white;
  width: 100%;
  height: 200px;
  margin-top: 20px;
  padding-inline: 10px;
  padding-block: 10px;
}
.user-subcription-details {
  display: flex;
  gap: 20px;
}
.current-plan {
  background: rgb(48, 171, 212);
  width: 35%;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  padding-inline: 5px;
  padding-block: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
.user-plan-div {
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  margin-top: 5px;
  gap: 10px;
  align-items: center;
  color: white;
}
.user-subcription-history {
  width: 65%;
  background: whitesmoke;
  border-radius: 5px;
}

/* edit user gender */
.edit-user-details {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  background-color: rgba(0, 0, 0, 0.8);
}
.userdetail-container {
  background: #fff;
  padding-inline: 20px;
  padding-block: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
}
.user-detail-form {
  min-width: 400px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.gender-div {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* friend list */
.show-friendList{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;

}

.no-stats{
  display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
    height: 100px;
    font-weight: 500;
}


/* viewMore */

.user-stat-filter{
  display: flex;
  /* flex-direction: column; */
  width: 46%;
  gap:20px;
  align-items: center;
  padding:10px 20px;
  text-align: center;
}

.user-stat-apply {
  width: 35%;
  padding: 10px;
  border-radius: 11px;
  border: 1px solid #80808061;
}

.str-date-vw{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.str-date-vw input{
  height: 40px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #80808061;
}


/* dashboard  */

.dashBoard{
  width: 83%;
  margin-left: 17%;
}

.flex-end{
  align-items: flex-end;
}

.w25{
  width: 25%;
}

.w-100{
  width: 100% !important;
}

.kpi-points{
  font-size: 20px;
  font-weight: 700;
}

.pointer{
  cursor: pointer;
}

.p10{
  padding: 10px;
}

.default-effect{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.kpi-section{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 10px;
}

.container-kpi{
  padding-inline: 20px;
    overflow: hidden;
    position: relative;
}

.line-chart-section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line-chart-section h3{
  color: white;
  text-align: center;
}

.margin-left{
  margin-left: 10px;
}
.margin-right{
  margin-right: 10px;
}

.al-center{
  align-items: center;
}

.btn-kpi{
  height: 30px;
  width: 45%;
  border-radius: 10px;
  border: 1px solid grey;
  cursor: pointer;
}

.bg-btn-col{
  background-color: red;
}